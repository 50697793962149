import { BasicDataStructure } from './enums/basic-data-structure';

export default class Config {
  public backendUrl: string;
  public basicDataStructure: BasicDataStructure;

  constructor(backendUrl: string, basicDataStructure: BasicDataStructure) {
    this.backendUrl = backendUrl;
    this.basicDataStructure = basicDataStructure;
  }
}
