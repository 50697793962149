import AutoIncrementingIdRequest from '@/model/request/auto-incrementing-id-request';
import axios from 'axios';
import Config from '@/model/config';

export default {
  namespaced: true,
  state: {
    config: Config,
    placeAutoIncrementId: false,
    toolAutoIncrementId: false,
    personAutoIncrementId: false,
  },
  mutations: {
    setConfig(state: any, payload: Config) {
      state.config = payload;
    },
    setPlaceAutoIncrementId(state: { placeAutoIncrementId: boolean }, payload: boolean) {
      state.placeAutoIncrementId = payload;
    },
    setToolAutoIncrementId(state: { toolAutoIncrementId: boolean }, payload: boolean) {
      state.toolAutoIncrementId = payload;
    },
    setPersonAutoIncrementId(state: { personAutoIncrementId: boolean }, payload: boolean) {
      state.personAutoIncrementId = payload;
    },
  },
  actions: {
    loadConfig({ commit, dispatch }: any) {
      return axios
        .get('/config/config.json')
        .then(({ data }) => {
          if (data) {
            commit('setConfig', data);
            axios.defaults.baseURL = data.backendUrl;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getPlaceAutoIncrementingId({ commit, dispatch }: any) {
      return axios
        .get(`/configuration/place-id`)
        .then(({ data }) => {
          if (data) {
            commit('setPlaceAutoIncrementId', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    setPlaceAutoIncrementingId({ commit, dispatch }: any, request: AutoIncrementingIdRequest) {
      return axios
        .post(`/configuration/place-id`, request)
        .then(() => {
          commit('setPlaceAutoIncrementId', request.autoIncrementing);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getToolAutoIncrementingId({ commit, dispatch }: any) {
      return axios
        .get(`/configuration/tool-id`)
        .then(({ data }) => {
          if (data) {
            commit('setToolAutoIncrementId', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    setToolAutoIncrementingId({ commit, dispatch }: any, request: AutoIncrementingIdRequest) {
      return axios
        .post(`/configuration/tool-id`, request)
        .then(() => {
          commit('setToolAutoIncrementId', request.autoIncrementing);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getPersonAutoIncrementingId({ commit, dispatch }: any) {
      return axios
        .get(`/configuration/person-id`)
        .then(({ data }) => {
          if (data) {
            commit('setPersonAutoIncrementId', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    setPersonAutoIncrementingId({ commit, dispatch }: any, request: AutoIncrementingIdRequest) {
      return axios
        .post(`/configuration/person-id`, request)
        .then(() => {
          commit('setPersonAutoIncrementId', request.autoIncrementing);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getConfig(state: { config: Config }) {
      return state.config;
    },
    isConfigLoaded(state: { config: Config }) {
      return !!state?.config?.backendUrl;
    },
    getBasicDataStructure(state: { config: Config }) {
      return state.config.basicDataStructure;
    },
    isPlaceAutoIncrementingId(state: { placeAutoIncrementId: boolean }) {
      return state.placeAutoIncrementId;
    },
    isToolAutoIncrementingId(state: { toolAutoIncrementId: boolean }) {
      return state.toolAutoIncrementId;
    },
    isPersonAutoIncrementingId(state: { personAutoIncrementId: boolean }) {
      return state.personAutoIncrementId;
    },
  },
};
