import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueAxios from 'vue-axios';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
// @ts-ignore
import vueWaypoint from './plugins/vueWaypoint';
import Requirement from '@/model/requirement';
import { checkGuestExpiry } from '@/helper/methods';
import { AuthenticationStatus } from '@/model/enums/authentication-status';
import vueDebounce from 'vue-debounce';
import { TypeIcon } from '@/model/enums/type-icon';
import { Route, RoutePath } from '@/model/enums/route';
import { permissionMixin } from '@/mixins/permissions';
import { formatterMixin } from '@/mixins/formatters';
import { calculatorMixin } from '@/mixins/calculators';
import { filterMixin } from '@/mixins/filter';
import { optionsMixin } from '@/mixins/options';
import { validationErrorsMixin } from '@/mixins/validationErrors';
import moment from 'moment';
import { Role } from './model/enums/role';
import { createApp } from 'vue';
import { i18n } from './i18n';
import { Composer } from 'vue-i18n';
import sweetAlert from './plugins/sweetalert';
import store from './store';
import { BootstrapVueNext } from 'bootstrap-vue-next';
import { vue3Debounce } from 'vue-debounce';
import { DatePicker } from 'v-calendar';

const { t }: Composer = i18n.global;

//Stop error resizeObserver by adding a 20ms delay
const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function (...args: any[]) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

// end of resizeObserver fix

const app = createApp(App);

app.use(VueAxios, axios);
app.use(sweetAlert);
app.use(vuetify);
app.use(BootstrapVueNext);
app.use(vueWaypoint);
app.use(vueDebounce);
app.use(i18n);
app.use(router);
app.use(store);
app.directive('debounce', vue3Debounce({ lock: true }));
app.component('VDatePicker', DatePicker);

const auth = 'Authorization';

axios.interceptors.request.use(
  async (config) => {
    await checkGuestExpiry().catch(() => {
      return Promise.reject(AuthenticationStatus.GUEST_EXPIRED);
    });
    if (
      store.getters['currentUserStorage/getCurrentUser'] &&
      store.getters['currentUserStorage/getCurrentUser']?.accessToken &&
      Date.now() >= store.getters['currentUserStorage/getCurrentUser'].accessTokenExpiry &&
      config.url !== '/authentication/refresh'
    ) {
      config.headers[auth] = '';
      await store.dispatch('currentUserStorage/refreshCredentials');
    }

    const user = store.getters['currentUserStorage/getCurrentUser'];
    if (user?.accessToken && config.url !== '/authentication/refresh') {
      config.headers[auth] = `Bearer ${user.accessToken}`;
      if (
        config.url !== '/messages/count' &&
        config.url !== '/user/update' &&
        !user?.roles.includes(Role.GUEST) &&
        (router.currentRoute.value.name === Route.ORGANIZATIONAL_MAP ||
          router.currentRoute.value.name === Route.ORGANIZATIONAL_MAP)
      ) {
        await store.dispatch('messageStorage/getUnseenMessageCounts');
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response && error?.response?.status === AuthenticationStatus.TOKEN_EXPIRED) {
      store.dispatch('currentUserStorage/logout');
      router.push({ name: Route.LOGIN });
      return new Promise(() => Object);
    } else if (error === AuthenticationStatus.GUEST_EXPIRED) {
      return Promise.reject(error);
    } else {
      return Promise.reject(
        error?.response?.data?.message ? error.response.data.message : 'notification.error.default',
      );
    }
  },
);

[permissionMixin, formatterMixin, calculatorMixin, filterMixin, optionsMixin, validationErrorsMixin].forEach(
  (mixin) => {
    app.mixin(mixin);
  },
);

app.mixin({
  methods: {
    pastDeadline(maxDate: string): boolean {
      return moment(new Date(maxDate)).add(1, 'd') < moment();
    },
    addOrRemoveItem(list: any[], item: any): void {
      const index = list.findIndex((added) => added === item);
      index > -1 ? list.splice(index, 1) : list.push(item);
    },
    removeItem(list: any[], item: any): any[] {
      const index = list.findIndex((listItem: any) => listItem === item);
      if (index >= 0) {
        list.splice(index, 1);
      }
      return list;
    },
    isContainer(requirement: Requirement): boolean {
      return requirement?.subrequirements?.length > 0 || !requirement.type;
    },
    iconPath(iconType: string): string {
      return `/images/${TypeIcon[iconType as keyof typeof TypeIcon]}`;
    },
    isItemSelected(item: any, list: any[]): boolean {
      return list?.includes(item);
    },
  },
  computed: {
    isAdminPage(): boolean {
      return (this as any).$route.path.startsWith(RoutePath.ADMIN);
    },
    isBasicInformationPage(): boolean {
      return (this as any).$route.path.startsWith(RoutePath.BASIC_INFORMATION);
    },
  },
});

app.mount('#app');
